/**
 * This is for the HOME PAGE, (for the slug `/` )
 * It is close to a duplicate of /pages/[...slug_national_standard]/index.tsx
 */

import React from 'react';
import { GetStaticProps } from 'next';
import { PATHS } from '@src/constants/site';
import {
  pageNationalStandardPart1ContentBlocksQuery,
  pageNationalStandardPart2ContentBlocksQuery,
  pageNationalStandardQuery
} from '@src/graphql/queries/pageNationalStandard.query';
import PageNationalStandardTemplate from '@src/templates/PageNationalStandard';
import {
  GlobalSettingsQuery,
  NationalBasePageQuery,
  PageNationalStandardPart1ContentBlocksQuery,
  PageNationalStandardPart2ContentBlocksQuery,
  PageNationalStandardQuery,
  PageNationalStandardQueryVariables
} from '@src/graphql/gql-types';
import {
  nationalBasePageQuery,
  globalSettingsQuery
} from '@src/graphql/queries';
import { apolloClient, setApolloHeaders } from '@src/services/apollo';
import { NationalStandardPageProps } from '@src/types/page';
import { mergeContentBlocks } from '@src/utils/helpers';
import { base64EncodeHero } from '@src/utils/build/base64EncodeHero';

type HomePage = NationalStandardPageProps;

const HomePage: React.FC<HomePage> = (props) => {
  return <PageNationalStandardTemplate {...props} />;
};

export default HomePage;

export const getStaticProps: GetStaticProps = async ({ preview }) => {
  if (preview) {
    setApolloHeaders(process.env.NEXT_PUBLIC_CONTENTFUL_PREVIEW_TOKEN);
  }

  const [
    globalSettingsQueryResponse,
    nationalBasePageQueryResponse,
    pageQueryResponse,
    pageNationalStandardPart1ContentBlocksQueryResponse,
    pageNationalStandardPart2ContentBlocksQueryResponse
  ] = await Promise.all([
    apolloClient.query<GlobalSettingsQuery>({
      query: globalSettingsQuery,
      variables: {
        preview: preview
      }
    }),
    apolloClient.query<NationalBasePageQuery>({
      query: nationalBasePageQuery,
      variables: {
        preview: preview
      }
    }),
    apolloClient.query<
      PageNationalStandardQuery,
      PageNationalStandardQueryVariables
    >({
      query: pageNationalStandardQuery,
      variables: { slug: PATHS.HOME, preview: preview }
    }),
    apolloClient.query<
      PageNationalStandardPart1ContentBlocksQuery,
      PageNationalStandardQueryVariables
    >({
      query: pageNationalStandardPart1ContentBlocksQuery,
      variables: { slug: PATHS.HOME, preview: preview }
    }),
    apolloClient.query<
      PageNationalStandardPart2ContentBlocksQuery,
      PageNationalStandardQueryVariables
    >({
      query: pageNationalStandardPart2ContentBlocksQuery,
      variables: { slug: PATHS.HOME, preview: preview }
    })
  ]);

  if (nationalBasePageQueryResponse?.errors) {
    console.error(nationalBasePageQueryResponse.errors);
  }

  if (pageQueryResponse?.errors) {
    console.error(pageQueryResponse.errors);
  }

  if (globalSettingsQueryResponse?.errors) {
    console.error(globalSettingsQueryResponse.errors);
  }

  if (pageNationalStandardPart1ContentBlocksQueryResponse?.errors) {
    console.error(pageNationalStandardPart1ContentBlocksQueryResponse.errors);
  }

  if (pageNationalStandardPart2ContentBlocksQueryResponse?.errors) {
    console.error(pageNationalStandardPart2ContentBlocksQueryResponse.errors);
  }

  const items = mergeContentBlocks([
    pageNationalStandardPart1ContentBlocksQueryResponse?.data,
    pageNationalStandardPart2ContentBlocksQueryResponse?.data
  ]);

  const pagePayload = await base64EncodeHero(pageQueryResponse?.data);

  const templatePropsData: NationalStandardPageProps = {
    pagePayload: pagePayload as PageNationalStandardQuery,
    globalSettingsPayload: globalSettingsQueryResponse?.data,
    nationalBasePagePayload: nationalBasePageQueryResponse?.data,
    pageContentBlocks: items
  };

  return {
    props: {
      ...templatePropsData
    }
  };
};
